import { Flex, Heading, Text,Image, Button } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import pic6 from '../images/pic6.png'
import {  motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


function WelcomePage(props) { 

  const {ref: myref, inView: myelem}= useInView()
  const myAnimation=useAnimation()

  const myAnimation21=useAnimation()
  const myAnimation22=useAnimation()
  const myAnimation23=useAnimation()

  useEffect(()=>
  {
    if(myelem == true)
    {
      myAnimation.start(
        {
          x:0,
          transition:{
            type:'spring', duration:1, bounce:0.3
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation.start(
        {
          x:'-100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation21.start(
        {
          x:0,
          transition:{
            type:'spring', duration:3, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation21.start(
        {
          x:'100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation22.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation22.start(
        {
          x:'100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation23.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation23.start(
        {
          x:'100vw'
        }
      )
    }

  },[myelem])

  return (
    <Flex ref={myref} overflow='hidden' flexDirection={{lg:'row',base:'column'}} w='100%' h={{lg:'100vh',md:'120vh',base:'140vh'}} bgGradient='Linear(to-r,#CB1C8D,#eab308 )' p='10vh' paddingBottom='0vh' alignItems='center' justifyContent='space-evenly'>
      <Flex  as={motion.Flex} animate={myAnimation} w={{lg:'60%',base:'100%'}} h={{lg:'100%',md:'50%',base:'30%'}} >
        <Image src={pic6} w='100%' h='100%' objectFit={{lg:'contain',base:'contain'}}/>
      </Flex>
      <Flex w={{base:'100%',lg:'40%'}} h={{lg:'60%',md:'40%',sm:'55%',base:'60%'}} flexDirection='column' justifyContent={{lg:'space-between',base:'space-evenly'}} alignItems={{base:'center',lg:'start'}} >
        <Heading as={motion.Flex} animate={myAnimation21} fontSize='3rem' fontFamily='sans-serif'>What is Metaverse? </Heading>
        <Text as={motion.Flex} animate={myAnimation22} fontSize='1.2rem' fontFamily='sans-serif'>The metaverse is the emerging 3-D-enabled digital space that uses virtual reality, augmented reality, and other advanced internet and semiconductor technology to allow people to have lifelike personal and business experiences online. All you need is a VR headset, Crypto wallet and a Windows PC.</Text>
        <Flex>
          <Button as={motion.Flex} animate={myAnimation23} h='7vh' textAlign='center' bgColor='#CB1C8D' color='white' mr='2vh' >What is Metaverse?</Button>
          <Button as={motion.Flex} animate={myAnimation23} h='7vh'  border='0.1px solid' borderColor='black' display={props.ButtonCheck}>More on virtual interacting</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default WelcomePage