import { Flex,Image,Link,Stack,Text } from '@chakra-ui/react'
import React from 'react'

function Newsitem(props) {
  return (
    <Link href={props.apiData.link}>
        <Flex flexDir='column-reverse' boxShadow= '10px 10px 20px rgba(255, 255, 255, 0.5)' border='1px solid black' minW='70vh' minH='50vh' mr='8vh' bgImage={props.apiData.img_link} _hover={{boxShadow:'none', color:'#eab308',transform:'scale(1.1)',transition: 'all 0.25s linear'}} bgSize='cover' backgroundPosition='center'>
            <Flex w='100%' h='20vh' alignItems='start' p='2vh' bg="rgba(0,0,0,0.6)" fontSize='1.8rem' >
                    <Text fontFamily='sans-serif' fontWeight='semibold'  >{props.apiData.title}</Text>
            </Flex>
        </Flex>
    </Link>
  )
}

export default Newsitem