import { Circle, Flex, Grid, Heading, Image, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React,{useEffect} from 'react'
import {  motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import f1 from '../images/icon-1.png'
import f2 from '../images/icon-2.png'
import f3 from '../images/icon-3.png'
import f4 from '../images/icon-4.png'
import f5 from '../images/icon-5.png'
import f6 from '../images/icon-6.png'
import w from '../images/world.jpg'

function FeaturesPage() {

    var Info_features=[
        {image:f1,title:'Feuling The Metaverse',text:'The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its'},
        {image:f2,title:'Interconnected Economies',text:'The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its'},
        {image:f3,title:'Non-fungible assets',text:'The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its'},
        {image:f4,title:'Incubation',text:'The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its'},
        {image:f5,title:'Initial Metaverse Offering',text:'The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its'},
        {image:f6,title:'Next Tier Metaverse Projects',text:'The Metaverse Is The Next Generation Of The Internet. As One Of Its Earliest Explorers, You Will Help Fuel Its'},
    ]

    const {ref: myref, inView: myelem}= useInView()
    const myAnimation=useAnimation()
    const myAnimation2=useAnimation()

    useEffect(()=>
  {
    if(myelem == true)
    {
      myAnimation.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.3, stiffness:80
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation.start(
        {
          x:'-100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation2.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.3, stiffness:80
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation2.start(
        {
          x:'100vw'
        }
      )
    }
  },[myelem])

  return (
   
        <Flex ref={myref} overflow='hidden' flexDirection='column' w='100%' bgColor='transparent' minH='190vh' p='10vh' alignItems='center' fontFamily='sans-serif' justifyContent='space-around'>
            <Text as={motion.Text} animate={myAnimation} color='#CB1C8D' fontSize='1.2rem'>Key Features</Text>
            <Heading as={motion.Text} animate={myAnimation2} fontSize='3.5rem' color='white' textAlign='center'>Access the future</Heading>
            <SimpleGrid w='100%' h='90%' p={{md:'5vh'}} minChildWidth='54vh' spacing='5vh' >
                {
                    Info_features.map((item,index) =>(

                    index<3 ?
                        <Flex as={motion.Text} animate={myAnimation} flexDirection='column' w={{lg:'54vh'}} h='64vh'  px='5vh' py='5vh' color='white' bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 1200%)' borderRadius='25px' justifyContent='space-around' _hover={{bgGradient:'linear(to-br,#252525 5% ,#eab308, #090910 1200%)', transition:'all 0.25 ease'}}>
                            <Image src={item.image} boxSize='5.4rem'/>
                            <Heading fontSize='1.45rem' fontFamily='sans-serif' fontWeight='extrabold'>{item.title}</Heading>
                            <Text fontSize='0.9rem' fontFamily='sans-serif' >{item.text}</Text>
                            <Link >
                                <Text fontSize='1rem' fontFamily='sans-serif' color='#CB1C8D'>Read more</Text>
                            </Link>
                        </Flex>
                    :
                        <Flex as={motion.Text} animate={myAnimation2} flexDirection='column' w={{lg:'54vh'}} h='64vh'  px='5vh' py='5vh' color='white' bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 1200%)' borderRadius='25px' justifyContent='space-around' _hover={{bgGradient:'linear(to-br,#252525 5% ,#eab308, #090910 1200%)', transition:'all 0.25 ease'}}>
                        <Image src={item.image} boxSize='5.4rem'/>
                        <Heading fontSize='1.45rem' fontFamily='sans-serif' fontWeight='extrabold'>{item.title}</Heading>
                        <Text fontSize='0.9rem' fontFamily='sans-serif' >{item.text}</Text>
                        <Link >
                            <Text fontSize='1rem' fontFamily='sans-serif' color='#CB1C8D'>Read more</Text>
                        </Link>
                        </Flex>
                    ))
                }
            </SimpleGrid>
            
        </Flex>
    
    
  )
}

export default FeaturesPage