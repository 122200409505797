import { Box, Button, Flex, Heading, Icon, IconButton, MenuIcon, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { motion } from 'framer-motion'

import {GiBlackHoleBolas, GiHamburgerMenu} from 'react-icons/gi'
import {BsMeta} from 'react-icons/bs'

function Navbar(props) {


    function OpenMenuBar()
    {
        props.setMenuCheck(!props.MenuCheck)
    }

  return (
    <>
        <Flex w='100%' h='12vh' alignItems='center' justifyContent='space-between' cursor='default' position='sticky' top='0' zIndex='999' bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 1450%)'>
            <div class='w-1/6 lg:w-1/4 md:w-1/4 base:w-1/5 h-full bg-gradient-to-r from-amber-200 to-yellow-500 flex items-center p-6 lg:justify-end md:justify-center sm:justify-center rounded-br-3xl'>
                <Flex as={motion.Flex} initial={{x:'-100vw'}} animate={{x:0}} transition={{type:'spring', duration:5, bounce:0.3}}  w='70%' justifyContent='space-around'>
                    <Icon as={BsMeta} boxSize={{lg:'2.6rem', md:'2rem', base:'2.6rem'}} color='#CB1C8D'  />
                    <Heading fontSize={{lg:'2.2rem',md:'1.8rem',base:'0rem'}} letterSpacing='tighter' color='white' fontFamily='sans-serif'>MetaSeed</Heading>
                </Flex>
            </div>

        {
            props.MenuCheck === false ?
            <Flex w={{md:'58%',sm:'0%'}} h='88vh' p='6' alignItems='center' fontFamily='sans-serif' fontSize={{lg:'1.1rem', md:'1.1rem',sm:'0rem'}} fontWeight='light' color='whiteAlpha.800' justifyContent='space-around' >
                <Text _hover={{color:'gray'}}>Use Metaverse</Text>
                <Text _hover={{color:'gray'}}>About Us</Text>
                <Text _hover={{color:'gray'}}>Contact</Text>
                <Text _hover={{color:'gray'}}>Earnings</Text>
                <Text _hover={{color:'gray'}}>Stats</Text>
            </Flex>
            :
            <></>
        }
            <Flex w={{md:'22%',sm:'65%'}} h='100%' p='5' alignItems='center' fontFamily='sans-serif'  fontWeight='semibold' color='whiteAlpha.800' justifyContent={{md:'space-evenly',sm:'start'}} >
                <Button bgColor='transparent' fontSize={{lg:'1.2rem', md:'1.1rem',sm:'1.3rem'}} _hover={{bgColor:'gray'}}>Log In</Button>
                <Button bgColor='transparent' fontSize={{lg:'1.2rem', md:'1.1rem',sm:'1.3rem'}} _hover={{bgColor:'gray'}}>Sign Up</Button>
            </Flex>

            {
                props.MenuCheck === false
                ?
                <IconButton as={GiHamburgerMenu} boxSize={{sm:'2rem',md:'0rem'}} color='white' bgColor='transparent' _hover={{bgColor:'gray'}} onClick={OpenMenuBar} mr='1rem'/>
                :
                <IconButton as={GiHamburgerMenu} boxSize={{sm:'2rem',md:'2rem'}} color='white' bgColor='transparent' _hover={{bgColor:'gray'}} onClick={OpenMenuBar} justifySelf='end' mr='1rem'/>
            }   

        </Flex>

    {
        (props.MenuCheck === true) ?
        <Flex flexDirection='column' position='sticky' zIndex='999' top='12vh' w='100%' h='88vh' bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 700%)' color='white' fontSize='1.9rem' fontWeight='bold'  >
            <Flex w='100%' h='20%' alignItems='center' justifyContent='center' _hover={{bgColor:'#090910'}} borderTop='0.1px solid'>Home</Flex>
            <Flex w='100%' h='20%' alignItems='center' justifyContent='center' _hover={{bgColor:'#090910'}} borderTop='0.1px solid'>About Us</Flex>
            <Flex w='100%' h='20%' alignItems='center' justifyContent='center' _hover={{bgColor:'#090910'}} borderTop='0.1px solid'>Contact</Flex>
            <Flex w='100%' h='20%' alignItems='center' justifyContent='center' _hover={{bgColor:'#090910'}} borderTop='0.1px solid'>Earnings</Flex>
            <Flex w='100%' h='20%' alignItems='center' justifyContent='center' _hover={{bgColor:'#090910'}} borderTop='0.1px solid'>Stats</Flex>
        </Flex>
        :
        <></>
    }

    </>
  )
}

export default Navbar