import { Flex, Heading, Text,Image, Button } from '@chakra-ui/react'
import React, {useEffect} from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation, motion } from 'framer-motion'

import pic6 from '../images/pic7.png'

function MorePage_home(props) {

  const {ref: myref, inView: myelem}= useInView()
  const myAnimation=useAnimation()

  const myAnimation21=useAnimation()
  const myAnimation22=useAnimation()
  const myAnimation23=useAnimation()

  useEffect(()=>
  {
    if(myelem == true)
    {
      myAnimation.start(
        {
          x:0,
          transition:{
            type:'spring', duration:1, bounce:0.3
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation.start(
        {
          x:'100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation21.start(
        {
          x:0,
          transition:{
            type:'spring', duration:3, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation21.start(
        {
          x:'-100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation22.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation22.start(
        {
          x:'-100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation23.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation23.start(
        {
          x:'-100vw'
        }
      )
    }

  },[myelem])

  return (
    <Flex overflow='hidden' ref={myref} flexDirection={{lg:'row',base:'column-reverse'}} w='100%'  h={{lg:'100vh',md:'120vh',base:'140vh'}} bgGradient='Linear(to-r,#86E5FF,#8FE3CF)' p='10vh' pr='5vh' paddingBottom='0vh' alignItems='center' justifyContent='space-evenly'>
      <Flex w={{base:'100%',lg:'40%'}}h={{lg:'60%',md:'40%',sm:'55%',base:'60%'}} flexDirection='column' justifyContent={{lg:'space-between',base:'space-evenly'}} alignItems={{base:'center',lg:'start'}} >
            <Heading as={motion.Flex} animate={myAnimation21} fontSize='3rem' fontFamily='sans-serif'>Join Decentraland</Heading>
            <Text as={motion.Flex} animate={myAnimation22} fontSize='1.2rem' fontFamily='sans-serif'>It is a virtual world built on a Blockchain where users can own virtual land, create and trade digital assets, and engage in various social and economic activities.It also offers several benefits, including ownership and control over virtual land, the ability to create and trade unique digital assets.</Text>
            <Flex>
            <Button as={motion.Flex} animate={myAnimation23} h='7vh'  bgColor='#CB1C8D' color='white' mr='2vh' display={props.ButtonCheck}>Explore Decentraland</Button>
             </Flex>
      </Flex>
      <Flex as={motion.div} animate={myAnimation} w={{lg:'75%',base:'100%'}} h={{lg:'100%',md:'50%',base:'30%'}}>
        <Image src={pic6} w='100%' h='100%' objectFit={{lg:'contain',base:'contain'}}/>
      </Flex>
    </Flex>
  )
}

export default MorePage_home