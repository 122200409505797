import React, { useEffect, useState } from 'react'
import { Flex,Text,Heading } from '@chakra-ui/react';
import Newsitem from './Newsitem';
import {  motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'



const options = {
	method: 'GET',
	headers: {
		'X-RapidAPI-Key': '6ff5241c93msh431e56482db5e84p1213e5jsn1d28e971cf3f',
		'X-RapidAPI-Host': 'ar-vr-news2.p.rapidapi.com'
	}
};


const URL= 'https://ar-vr-news2.p.rapidapi.com/api/news'

function News_home() {

  const [apiData,setapiData]=useState([]);

  const fetchdata = async() =>
  {
    const response=await fetch(URL,options)

    const data = await response.json()
    setapiData(data.data)
    console.log(data.data)
    
  }

  const {ref: myref, inView: myelem}= useInView()
  const myAnimation=useAnimation()
  const myAnimation2=useAnimation()

  useEffect(()=>{

    fetchdata()

    if(myelem == true)
    {
      myAnimation.start(
        {
          y:0,
          transition:{
            type:'spring', duration:1.4, bounce:0.15
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation.start(
        {
          y:'-1000vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation2.start(
        {
          x:0,
          transition:{
            type:'spring', duration:1.5, bounce:0.4
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation2.start(
        {
          x:'-100vw'
        }
      )
    }

  },[myelem])


  return (
    
    <Flex ref={myref} overflow='hidden' flexDirection='column' w='100%' h={{md:'100vh',base:'120vh'}} p={{md:'10vh',base:'5vh'}} alignItems='center' justifyContent={{md:'space-between',base:'space-evenly'}} color='#fffff9'>
      
      <Heading as={motion.Heading} animate={myAnimation} fontFamily='sans-serif' fontSize='3.5rem' textAlign='center'>Latest News of Metaverse/VR/AR</Heading>
      
      <Flex as={motion.Heading} animate={myAnimation2} w='100%' h='60vh' alignItems='center' overflowX='scroll' overflowY='hidden' css={{'&::-webkit-scrollbar': {  width: '1px',}, }} > 
      {
        apiData.slice(1,20).map((item)=>
        {
          return(
            <Newsitem apiData={item} />
          );
        }
        )
      
      
      }
      </Flex>
    </Flex>
    
  )
}

export default News_home