import { Flex, Image, Link } from '@chakra-ui/react'
import React,{useEffect} from 'react'
import {  motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import p1 from '../images/partner-2.png'
import p2 from '../images/partner-3.png'
import p3 from '../images/partner-4.png'
import p4 from '../images/partner-5.png'
import p5 from '../images/partner-6.png'
import p6 from '../images/partner-7.png'

function Partner() {
    var partnerlist=[
      {image:p1,path:'https://www.akila3d.com/'},
      {image:p2,path:'https://www.akila3d.com/'},
      {image:p3,path:'https://www.akila3d.com/'},
      {image:p4,path:'https://www.akila3d.com/'},
      {image:p5,path:'https://www.akila3d.com/'},
      {image:p6,path:''}]

      const {ref: myref, inView: myelem}= useInView()
      const myAnimation=useAnimation()

      useEffect(()=>
      {
        if(myelem == true)
        {
          myAnimation.start(
            {
              x:0,
              transition:{
                type:'spring', duration:1.5, bounce:0.4
              }
            }
          )
        }
    
        if(myelem == false)
        {
          myAnimation.start(
            {
              x:'100vw'
            }
          )
        }
      },[myelem])

  return (
    <Flex overflow='hidden' ref={myref} w='100%' h='30vh' justifyContent='space-around' p='5vh' bgGradient='linear(to-br,#090910 10% ,#eab308, #090910 1500%)'>
        {
            partnerlist.map(item=>(
              <Link as={motion.Flex} animate={myAnimation} to={item.path}>
                <Image  src={item.image} boxSize='8rem' objectFit='contain' />
              </Link>
            ))
        }

    </Flex>
  )
}

export default Partner