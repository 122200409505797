import { Flex, Heading, Icon, Text, keyframes } from '@chakra-ui/react'
import React,{useEffect} from 'react'
import {BsMeta, BsFacebook,BsInstagram,BsTwitter} from 'react-icons/bs'
import {  motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

function Footer() {

    const animationKeyframes = keyframes`
    0% { transform: scale(1) rotate(0deg); }
    50% { transform: scale(1) rotate(180deg);  }
    100% { transform: scale(1) rotate(360deg);}
    `;

    const animation = `${animationKeyframes} 7s ease-in-out infinite`;

    const {ref: myref, inView: myelem}= useInView()
    const myAnimation=useAnimation()
    const myAnimation2=useAnimation()
    const myAnimation3=useAnimation()

    useEffect(()=>
  {
    if(myelem == true)
    {
      myAnimation.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2, bounce:0.2
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation.start(
        {
          x:'-100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation2.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2,bounce:0.2
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation2.start(
        {
          x:'100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation3.start(
        {
          y:0,
          transition:{
            type:'spring', duration:2,bounce:0.2
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation3.start(
        {
          y:'100vw'
        }
      )
    }
  },[myelem])

  return (
    <Flex ref={myref} overflow='hidden' w='100%' h='40vh' alignItems='center' justifyContent='center'bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 1500%)' p={{md:'10vh'}}  >
        <Icon animation={animation} as={BsMeta} position='absolute' boxSize='40vh' color='#CB1C8D' opacity='0.2'/>
        <Flex w='100%' justifyContent='space-evenly' alignItems='center'>
            <Flex as={motion.Flex} animate={myAnimation} w={{lg:'35%'}} flexDirection={{lg:'row',base:'column'}} fontSize='1.1rem' color='white' fontWeight='bold' justifyContent='space-around'>
                <Text>About us</Text>
                <Text>Features</Text>
                <Text>Contact</Text>
                <Text>Projects</Text>
            </Flex>
            <Heading as={motion.Heading} animate={myAnimation3} color='white' fontWeight='extrabold' zIndex='999'>MetaSeed</Heading>
            <Flex as={motion.Flex} animate={myAnimation2} w={{lg:'35%'}} fontSize='1.1rem' flexDirection={{lg:'row',base:'column'}} color='white' fontWeight='bold' justifyContent='space-around'>
                <Text>Follow us on:</Text>
                <Flex w='fit-content' alignItems='center'>
                    <Icon as={BsFacebook} color='darkblue'/>
                    <Text color='gray.500'>Facebook</Text>
                </Flex>
                <Flex w='fit-content' alignItems='center'>
                    <Icon as={BsInstagram} color='orange.500' />
                    <Text color='gray.500'>Instagram</Text>
                </Flex>
                <Flex w='fit-content' alignItems='center'>
                    <Icon as={BsTwitter} color='blue.400'/>
                    <Text color='gray.500'> Twitter</Text>
                </Flex>
            </Flex>
        </Flex>
    </Flex>
  )
}

export default Footer