import { Flex } from '@chakra-ui/react';
import './App.css';
import Navbar from './component/Navbar';
import Home from './component/Home';
import { useState } from 'react';
import Footer from './component/Footer';

function App() {

  const [MenuCheck,setMenuCheck]=useState(false)

  return (
   <Flex flexDirection='column' w='100%' minH='100vh'  bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 700%)'>
      <Navbar MenuCheck={MenuCheck} setMenuCheck={setMenuCheck}/>
      <Home MenuCheck={MenuCheck} />
      <Footer />
   </Flex>
  );
}

export default App;
