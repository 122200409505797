import { Box, Flex, Heading,Text,Image, keyframes } from '@chakra-ui/react'
import {motion, useAnimation} from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import React,{useEffect} from 'react'

function CardItem(props) {

  const animationKeyframes = keyframes`
  0% { transform: scale(1) translateX(0px) ;  }
  25% { transform: scale(1) translateX(30px) ; }
  50% { transform: scale(1) translateX(0px) ;  }
  75% { transform: scale(1) translateX(-30px) ;  }
  100% { transform: scale(1) translateX(0px) ;}
`;

  const animation = `${animationKeyframes} 4s ease-in-out infinite`;

  const myAnimation=useAnimation()

  useEffect(()=>
  {
    if(props.scrollcheck == true && props.index % 2 ==0 )
    {
      myAnimation.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.3
          }
        }
      )
    }

    if(props.scrollcheck == true && props.index % 2 !=0)
    {
      myAnimation.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2, bounce:0.3
          }
        }
      )
    }

    if(props.scrollcheck == false)
    {
      myAnimation.start(
        {
          x:'-100vw'
        }
      )
    }

    
  },[props.scrollcheck])

  return (
    <Flex as={motion.Flex} animate={myAnimation} flexDirection='column' w={{md:'48.5%',base:'100%'}} h={{lg:'52vh',md:'65vh',base:'58vh'}} bgColor='#F5F5F5' border='1px solid' borderColor='black' my='2.4vh' boxShadow= '10px 10px 7px rgba(0, 0, 0, 0.5)' _hover={{transition:"all .25s ease",transform:'scale(1.04)', bgColor:'#DDDDDD', boxShadow:'0px 0px 0px white'}}>
        <Flex w='100%' h='66%' bgGradient='linear(to-br,#090910 5% ,#eab308, #090910 700%)' justifyContent='center'>
            <Image src={props.pic} w='100%' h='100%' objectFit='cover' />
        </Flex>
        <Flex flexDirection='column' w='100%' h='33%' p='3vh' fontFamily='sans-serif' color='black'>
            <Heading fontWeight='medium' fontSize='1.6rem'>{props.str1}</Heading>
            <Text mt='1.5vh' fontSize='0.95rem' fontWeight='light' color='blackAlpha.700'>{props.str2}</Text>
        </Flex>
    </Flex>
  )
}

export default CardItem