import { Button, Flex, Heading, Icon, Text, Image, keyframes } from '@chakra-ui/react'
import {GiBlackHoleBolas} from 'react-icons/gi'
import {motion} from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

import React,{useEffect} from 'react'
import pic1 from '../images/pic1.png'

function HeroSection(props) {

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  25% { transform: scale(1) rotate(0);  }
  50% { transform: scale(1) rotate(180deg);  }
  75% { transform: scale(1) rotate(180deg); opacity:0 }
  100% { transform: scale(1) rotate(0);}
`;

const animation = `${animationKeyframes} 7s ease-in-out infinite`;

const animationKeyframes2 = keyframes`
  0% { transform: scale(1.1);  }
  50% { transform: scale(0.9); opacity:0.1}
  100% { transform: scale(1.1); }
`;

const animation2 = `${animationKeyframes2} 4s ease-in-out infinite`;

const {ref: myref, inView: myelem}= useInView()
const myAnimation=useAnimation()
const myAnimation2=useAnimation()
const myAnimation3=useAnimation()
const myAnimation4=useAnimation()

useEffect(()=>
{
  if(myelem == true)
  {
    myAnimation.start(
      {
        x:0,
        transition:{
          type:'spring', duration:2, bounce:0.2, stiffness:80
        }
      }
    )
  }

  if(myelem == false)
  {
    myAnimation.start(
      {
        x:'-100vw'
      }
    )
  }

  if(myelem == true)
    {
      myAnimation2.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation2.start(
        {
          x:'-100vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation3.start(
        {
          x:0,
          transition:{
            type:'spring', duration:2.5, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation3.start(
        {
          x:'-1000vw'
        }
      )
    }

    if(myelem == true)
    {
      myAnimation4.start(
        {
          y:0,
          transition:{
            type:'spring', duration:2, bounce:0.1
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation4.start(
        {
          y:'-1000vw'
        }
      )
    }

},[myelem])
  
return (
    <Flex ref={myref} w='100%' h='88vh' p={{lg:'12vh 12vh 8vh 12vh',md:'10vh 12vh 6vh 12vh',base:'8vh 10vh 4vh 10vh'}}>
        <Flex h='100%' w={{base:'100%',md:'60%'}} flexDirection='column' fontFamily='sans-serif' color='white' letterSpacing='tight' justifyContent='space-between' alignItems={{md:'start',base:'center'}} >
            <Heading as={motion.Heading} animate={myAnimation} fontSize={{lg:'5rem',md:'4rem',sm:'2.8rem'}} textAlign={{base:'center',md:'left'}}>Discover the complete Metaverse framework </Heading>
            <Flex w='80%'>
                <Text as={motion.Text} animate={myAnimation2} fontSize={{lg:'1.3rem',md:'1rem',base:'1rem'}} fontWeight='thin'>Experience life in many ways and interact with each other in an immersive virtual world</Text>
            </Flex>
            <Flex w='100%' h='13%' justifyContent={{lg:'space-between',md:'space-evenly',sm:'space-evenly'}} >
                <Button as={motion.button} animate={myAnimation3} w={{lg:'45%',md:'40%',base:'40%'}} h={{lg:'100%',md:'85%',base:'70%'}} display={props.ButtonCheck} bgGradient='Linear(to-r,#fde68a,#eab308)' fontSize={{lg:'1.4rem',md:'1.2rem',sm:'0.8rem'}} fontWeight='bold'>
                    <Icon as={GiBlackHoleBolas} mx={{md:'0.25rem'}} boxSize={{md:'1.8rem',sm:'1.4rem'}} color='black' />
                    <Text mx='0.25rem'>Explore</Text>
                </Button>
                <Button as={motion.button} animate={myAnimation3} w={{lg:'45%',md:'40%',base:'40%'}} h={{lg:'100%',md:'85%',base:'70%'}} display={props.ButtonCheck} bgColor='transparent' border='1px solid' borderColor='#eab308' fontSize={{lg:'1.4rem',md:'1.2rem',sm:'0.8rem'}}>
                    Connect
                </Button>
            </Flex>
        </Flex>

        <Flex as={motion.div} animate={myAnimation4} w={{md:'40%',base:'0%'}} h='100%' alignItems='start'>
            <Image src={pic1} boxSize={{lg:'34rem',md:'22rem',sm:'0rem'}}  objectFit='cover'>

            </Image>
        </Flex>
    </Flex>
  )
}

export default HeroSection