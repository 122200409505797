import { Flex } from '@chakra-ui/react'
import React from 'react'
import HeroSection from './HeroSection'
import Cards from './Cards'
import WelcomePage_home from './WelcomePage_home'
import MorePage_home from './MorePage_home'
import CryptoPage_home from './CryptoPage_home'
import IOTPage_home from './IOTPage_home'
import News_home from './News_home'
import CryptoGraph from './CryptoGraph'
import FeaturesPage from './FeaturesPage'
import Partner from './Partner'
import Footer from './Footer'

function Home(props) {

  function ButtonCheck()
    {
        if(props.MenuCheck==true)
        {
            return 'none'
        }
        else
        {
            return 'flex'
        }
    }

  return (
    <Flex w='100%' h='100%' flexDirection='column'>
        <HeroSection  ButtonCheck={ButtonCheck} />
        <Cards />
        <WelcomePage_home  ButtonCheck={ButtonCheck}/>
        <MorePage_home  ButtonCheck={ButtonCheck}/>
        <CryptoPage_home  ButtonCheck={ButtonCheck}/>
        <IOTPage_home ButtonCheck={ButtonCheck} />
        <News_home />
        <Partner />
        <FeaturesPage />
        <CryptoGraph />
        
    </Flex>
  )
}

export default Home