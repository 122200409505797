import { Flex, Heading,Text,Grid } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CardItem from './CardItem'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

import pic2 from '../images/pic2.png'
import pic3 from '../images/pic3.png'
import pic4 from '../images/pic4.png'
import pic5 from '../images/pic5.png'

function Cards() {

  const [checkScrollCards,setCheckScroll]=useState(false)

    var CardInfo=[
        {str1:'Buy a VR headset',str2:'An Oculus Quest 2 virtual reality headset can allow you to grab virtual objects and interact with them',pic:pic2},
        {str1:'Access the Metaverse',str2:'Enter the collection of virtual worlds that mimic the physical world.',pic:pic3},
        {str1:'Blockchain and crypto assets',str2:'Crypto-enabled payments have become a mainstream evolution in a virtual ecosystem',pic:pic4},
        {str1:'Start Creating',str2:'If you want to start creating your avatar, profile and NFTS, we have documentation and tutorial in our developer portal',pic:pic5},
    ]

  const {ref: myref, inView: myelem}= useInView()
  const myAnimation=useAnimation()

  useEffect(()=>
  {
    if(myelem == true)
    {
      myAnimation.start(
        {
          y:0,
          transition:{
            type:'spring', duration:1, bounce:0.2, stiffness:80
          }
        }
      )
    }

    if(myelem == false)
    {
      myAnimation.start(
        {
          y:'-1000vw'
        }
      )
    }

    if(myelem == true)
    {
      setCheckScroll(true)
    }

    if(myelem == false)
    {
      setCheckScroll(false)
    }

  },[myelem])

  return (
   <Flex overflow='hidden'  flexDirection='column' w='100%' minH='100%' bgColor='#fffff9' p={{md:'10vh',base:'5vh'}} color='black' alignItems='center'>
        <Flex ref={myref} flexDirection='column' alignItems='center' w={{lg:'48%',md:'55%',base:'100%'}} h='23vh' justifyContent='space-between' fontFamily='sans-serif' >
            <Heading as={motion.Heading} animate={myAnimation} textAlign='center' fontSize='3.2rem'>Get Started</Heading>
            <Text as={motion.Text} animate={myAnimation} textAlign='center' fontSize='1.2rem'>The Metaverse is a nebulous, digitally mixed reality with both non-fungible and infinite items and personas not bound by conventional physics and limitations.</Text>
        </Flex>
        <Flex ref={myref} flexDirection={{md:'row',sm:'column'}} w='100%' flexWrap='wrap' justifyContent='space-between' my='5vh' alignItems='center'>
            {
                CardInfo.map((obj,index) =>
                    <CardItem scrollcheck={checkScrollCards} index={index} str1={obj.str1} str2={obj.str2} pic={obj.pic}/>
                )
            }
        </Flex>
   </Flex>
  )
}

export default Cards