import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation, motion } from 'framer-motion'
import moment from 'moment/moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {  Button, Flex, Heading,Image } from '@chakra-ui/react';
import BtcPic from '../images/btc.png'
import EthPic from '../images/eth.png'
import BnbPic from '../images/bnb.png'
import SolPic from '../images/sol.png'
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

const URL='https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=30'
const URL2='https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=30'
const URL3 ='https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=usd&days=30'
const URL4 ='https://api.coingecko.com/api/v3/coins/solana/market_chart?vs_currency=usd&days=30'

function CryptoGraph() {

    const [CoinData,setCoinData]=useState({
        datasets:[],
    })

    const [CoinData2,setCoinData2]=useState({
        datasets:[],
    })

    const [CoinData3,setCoinData3]=useState({
        datasets:[],
    })

    const [CoinData4,setCoinData4]=useState({
        datasets:[],
    })

    const options={
        responsive:true
    }

const fetchData = async() =>
{
    const response = await fetch(URL)
    const result= await response.json()

    const time=[]

    for(const val of result.prices)
    {
        let temp = val[0]

        time.push(moment(temp).format('MMM DD, YYYY'))
    }
   console.log(time)

   const price=[]

    for(const val of result.prices)
    {
        price.push(val[1].toFixed(2))
    }
   console.log(price)

   setCoinData(
    {
        labels:time,
        datasets:[
           {
            fill:true,
             label:'Bitcon price flow',
             data:price,
             backgroundColor:'#eab308',
             borderColor:'#fde68a'
           }
        ]
    }
   )

   const response2 = await fetch(URL2)
   const result2= await response2.json()

    const time2=[]

    for(const val of result2.prices)
    {
        let temp = val[0]

        time2.push(moment(temp).format('MMM DD, YYYY'))
    }
   console.log(time2)

   const price2=[]

    for(const val of result2.prices)
    {
        price2.push(val[1].toFixed(2))
    }
   console.log(price2)

   setCoinData2(
    {
        labels:time2,
        datasets:[
           {
            fill:true,
             label:'Ethereum price flow',
             data:price2,
             backgroundColor:'#eab308',
             borderColor:'#fde68a'
           }
        ]
    }
   )

   const response3 = await fetch(URL3)
   const result3= await response3.json()

    const time3=[]

    for(const val of result3.prices)
    {
        let temp = val[0]

        time3.push(moment(temp).format('MMM DD, YYYY'))
    }
   console.log(time3)

   const price3=[]

    for(const val of result3.prices)
    {
        price3.push(val[1].toFixed(2))
    }
   console.log(price3)

   setCoinData3(
    {
        labels:time3,
        datasets:[
           {
            fill:true,
             label:'BNB price flow',
             data:price3,
             backgroundColor:'#eab308',
             borderColor:'#fde68a'
           }
        ]
    }
   )

   const response4 = await fetch(URL4)
    const result4= await response4.json()

    const time4=[]

    for(const val of result4.prices)
    {
        let temp = val[0]

        time4.push(moment(temp).format('MMM DD, YYYY'))
    }
   console.log(time4)

   const price4=[]

    for(const val of result4.prices)
    {
        price4.push(val[1].toFixed(2))
    }
   console.log(price4)

   setCoinData4(
    {
        labels:time4,
        datasets:[
           {
            fill:true,
             label:'Solana price flow',
             data:price4,
             backgroundColor:'#eab308',
             borderColor:'#fde68a',
           }
        ]
    }
   )
    
}

const {ref: myref, inView: myelem}= useInView()
const myAnimation=useAnimation()
const myAnimation2=useAnimation()
const myAnimation3=useAnimation()
const myAnimation4=useAnimation()

useEffect(()=>
{
    fetchData()

    if(myelem == true)
{
  myAnimation.start(
    {
      y:0,
      transition:{
        type:'spring', duration:1.4, bounce:0.11
      }
    }
  )
}

if(myelem == false)
{
  myAnimation.start(
    {
      y:'-1000vw'
    }
  )
}

if(myelem == true)
{
  myAnimation2.start(
    {
      x:0,
      transition:{
        type:'spring', duration:2.5, bounce:0.35
      }
    }
  )
}

if(myelem == false)
{
  myAnimation2.start(
    {
      x:'100vw'
    }
  )
}

if(myelem == true)
{
  myAnimation3.start(
    {
      x:0,
      transition:{
        type:'spring', duration:2.5, bounce:0.35
      }
    }
  )
}

if(myelem == false)
{
  myAnimation3.start(
    {
      x:'-100vw'
    }
  )
}

if(myelem == true)
{
  myAnimation4.start(
    {
      y:0,
      transition:{
        type:'spring', duration:2.6, bounce:0.1
      }
    }
  )
}

if(myelem == false)
{
  myAnimation4.start(
    {
      y:'1000vw'
    }
  )
}

},[myelem])

const [datachoice,setdatachoice]=useState(1)

function DisplayData(check)
{
    setdatachoice(check)
}

function getDisplaypic()
{
    if(datachoice==1)
    {
        return BtcPic
    }
    if(datachoice==2)
    {
        return EthPic
    }
    if(datachoice==3)
    {
        return BnbPic
    }
    if(datachoice==4)
    {
        return SolPic
    }
}

function getDisplayname()
{
    if(datachoice==1)
    {
        return 'Bitcoin'
    }
    if(datachoice==2)
    {
        return 'Ethereum'
    }
    if(datachoice==3)
    {
        return 'BinanceCoin'
    }
    if(datachoice==4)
    {
        return 'Solana'
    }
}



  
return (
    <Flex ref={myref} overflow='hidden' w='100%' h='120vh' flexDirection='column' bgGradient='linear(to-r,#BE6DB7,#FFDBA4)' px='5vh' py='10vh' alignItems='center'>
        <Heading as={motion.Heading} animate={myAnimation} fontSize='3.5rem' fontFamily='sans-serif' fontWeight='bold' textAlign='center' mb='5vh' >CryptoCurrency Live Charts</Heading>
            
            <Flex flexDirection='column' w='85%' h='85%' >
              <Flex alignItems='center'>
                    <Flex as={motion.Flex} animate={myAnimation3} w='50%'>
                        <Button w='10%' bgGradient='linear(to-r,#fde68a,#eab308)' color='white' _hover={{color:'black'}} onClick={()=> DisplayData(1)}>BTC</Button>
                        <Button w='10%' bgGradient='linear(to-r,#fde68a,#eab308)' color='white' _hover={{color:'black'}} onClick={()=> DisplayData(2)}>ETH</Button>
                        <Button w='10%' bgGradient='linear(to-r,#fde68a,#eab308)' color='white' _hover={{color:'black'}} onClick={()=> DisplayData(3)}>BNB</Button>
                        <Button w='10%' bgGradient='linear(to-r,#fde68a,#eab308)' color='white' _hover={{color:'black'}} onClick={()=> DisplayData(4)}>SOL</Button>
                    </Flex>
                    <Flex as={motion.Heading} animate={myAnimation2} alignItems='center' w='50%' justifyContent='end'>
                        <Image src={getDisplaypic()} boxSize='2.8rem' />
                        <Heading color='#CB1C8D' fontSize='1.5rem' >{getDisplayname()}</Heading>
                    </Flex>
                </Flex>
                <Flex as={motion.Flex} animate={myAnimation4}>
                {
                    datachoice == 1 ?
                    <Line options={options} data={CoinData} />
                    :
                    [
                        datachoice == 2 ?
                        <Line options={options} data={CoinData2} />
                        :
                        [
                            datachoice == 3 ?
                            <Line options={options} data={CoinData3} />
                            :
                            <Line options={options} data={CoinData4} />
                        ]
                    ]
                }
                </Flex>
            </Flex>
           
    </Flex>
)
}

export default CryptoGraph